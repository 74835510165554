import React from 'react';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Row } from 'src/components/Row';
import { IIndicatorType } from 'src/interfaces/store.interface';
import { useSelector } from 'react-redux';
import IndicatorWithPlot from '../../indicatorWithPlot';

type Props = {
  indicators: IIndicatorType[];
};

const Indicators: React.FC<Props> = ({ indicators }) => {
  const { storeGraphs_loading, storeWeekGraphsLoading } = useSelector((state: any) => state.graphs);
  const { outsideTrafficLoading } = useSelector((state: any) => state.areas);

  if (storeGraphs_loading || storeWeekGraphsLoading || outsideTrafficLoading) {
    return (
      <div className="animate-pulse flex flex-row gap-3 my-3">
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
          <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
          <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
          <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
          <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      {indicators.map((item) => (
        <Col size="6" key={`indicator-${item.id}`}>
          <Card className="flex-1 justify-between">
            <IndicatorWithPlot
              key={item.id}
              icon={item.icon}
              title={item.title}
              value={item.value}
              name={item.name}
              percent={item.percent}
              data={item.data}
              tooltip={item.tooltip}
              plotName={item.plotName}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Indicators;
