/* eslint-disable react/jsx-wrap-multilines */
import { uniqueId } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col } from 'src/components/Col';
import { RangeDatePicker } from 'src/components/datepicker';
import { Filters as PersonFilters, FiltersProps } from 'src/components/Filters';
import Layout from 'src/components/layout';
import ExtraHeaders from 'src/components/layout/header/extraHeaders';
import { Row } from 'src/components/Row';
import { AGE_FILTER, GENDER_FILTER } from 'src/constants/filters';
import { IUseParams } from 'src/interfaces/common.interface';
import { DoD } from 'src/interfaces/graph.interface';
import { IHeaderData, IIndicatorType } from 'src/interfaces/store.interface';
import * as ActionTypes from 'src/store/actions';
import {
  convertOneHourPercentageToMinutesAndSeconds,
  getHoursMinutesAndSeconds,
  getSimpleMinutesAndSeconds,
  getTimestampOf,
} from 'src/utils/helpers';

import PerformanceGraph from 'src/components/PerformanceGraph/PerformanceGraph';
import TimeAndPopularity from './components/TimeAndPopularity';
import QueueAndTraffic from './components/QueueAndTraffic';
import Indicators from './components/Indicators';
import SummaryReport from './components/SummaryReport';
import {
  storePerformanceCSVDownloadData,
  storePerformancePlotData,
  storePerformancePlotLayout,
} from './utils/performance.util';

const FILTERS: FiltersProps['filters'] = [GENDER_FILTER, AGE_FILTER];

const StoreDetail: React.FC = () => {
  const { id: storeId } = useParams<IUseParams>();
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);
  const { storeGraphs_loading, storeWeekGraphsLoading } = useSelector((state: any) => state.graphs);
  const { outsideTrafficLoading } = useSelector((state: any) => state.areas);

  const { store, storeToday, store_loading } = useSelector((state: any) => state.stores);
  const { interval, storeWeekGraphs, footfallDayOverDayGraphs, allStoresAvgTimeDoDGraphs } = useSelector(
    (state: any) => state.graphs,
  );

  const intervalLabel = `${moment(startDate).format('DD/MM/YYYY HH:mm')} - ${moment(endDate).format(
    'DD/MM/YYYY HH:mm',
  )}`;

  useEffect(() => {
    dispatch(
      ActionTypes.getStore.request({
        storeId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
    dispatch(
      ActionTypes.getStoreTills.request({
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        storeId,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
    dispatch(
      ActionTypes.getOutsideTraffic.request({
        storeId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
    dispatch(
      ActionTypes.getCalendarHeatmap.request({
        storeId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );

    dispatch(
      ActionTypes.getStoreTimeSpentGraph.request({
        shopId: storeId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );

    dispatch(
      ActionTypes.getDayOverDayGraphs.request({
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        storeId,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );

    dispatch(
      ActionTypes.getAllStoresAvgTimeDoDGraphs.request({
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
  }, [dispatch, startDate, endDate, storeId, genderFilter, ageFilter]);

  useEffect(() => {
    dispatch(
      ActionTypes.getStoreToday.request({
        storeId,
      }),
    );
  }, [dispatch, storeId]);

  useEffect(() => {
    dispatch(
      ActionTypes.getStoreGraphs.request({
        shopId: storeId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        interval,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
  }, [dispatch, startDate, endDate, storeId, genderFilter, ageFilter, interval]);

  useEffect(() => {
    dispatch(
      ActionTypes.getWeekStoreGraphs.request({
        shopId: storeId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
  }, [dispatch, startDate, endDate, storeId, genderFilter, ageFilter]);

  const [headerData, setHeaderData] = useState<IHeaderData>({
    address: '',
    footFall: '',
    location: '',
    timeSpent: '',
  });
  const [indicators, setIndicators] = useState<IIndicatorType[]>([]);

  useEffect(() => {
    const averageTime =
      (store.indoor_time_metrics?.average_time && getSimpleMinutesAndSeconds(store.indoor_time_metrics.average_time)) ||
      0;
    const averageTimeToday = getHoursMinutesAndSeconds(storeToday?.indoor_time_metrics?.average_time) || 0;

    setHeaderData({
      address: `${store.address_1} ${store.address_2}`,
      footFall: storeToday.indoor_footfall_metrics,
      location: store.name,
      timeSpent: String(averageTimeToday),
    });

    const storeWeekGraphsData =
      storeWeekGraphs && storeWeekGraphs.graph_data && storeWeekGraphs.graph_data.length > 0
        ? storeWeekGraphs.graph_data
        : [];

    const { data: totalFootfallGraph = [] } =
      storeWeekGraphsData.length > 0
        ? storeWeekGraphsData.find((graph: { name: string }) => graph.name === 'store_total_footfall_graph') || []
        : [];
    const { data: avgTimeGraph = [] } =
      storeWeekGraphsData.length > 0
        ? storeWeekGraphsData.find((graph: { name: string }) => graph.name === 'store_average_time_graph') || []
        : [];

    const avgTimeGraphFormatted = avgTimeGraph?.length
      ? avgTimeGraph.map((t: number) => convertOneHourPercentageToMinutesAndSeconds(t))
      : [];

    const storeTimeSpent = allStoresAvgTimeDoDGraphs?.find(
      (st: { store: { id: number } }) => st.store.id === Number(storeId),
    );

    const tooltipAvgTime = avgTimeGraph.map((value: number) => convertOneHourPercentageToMinutesAndSeconds(value));

    const storeDoD = ((): DoD => {
      const values: DoD = {
        footfallDoD: {
          percentage: '0',
          icon: 'empty',
        },
        avgTimeDoD: {
          percentage: '0',
          icon: 'empty',
        },
      };

      if (footfallDayOverDayGraphs === '' && storeTimeSpent && storeTimeSpent.result === null) {
        return values;
      }
      try {
        const { percentage: footfallPercentage } = footfallDayOverDayGraphs || {};
        const { result: { percentage: avgTimePercentage = {} } = {} } = storeTimeSpent || {};
        return {
          footfallDoD:
            typeof footfallPercentage === 'number'
              ? {
                percentage: footfallPercentage.toFixed(0),
                icon: footfallPercentage > 0 ? 'up' : 'down',
              }
              : values.footfallDoD,
          avgTimeDoD:
            typeof avgTimePercentage === 'number'
              ? {
                percentage: avgTimePercentage.toFixed(0),
                icon: avgTimePercentage > 0 ? 'up' : 'down',
              }
              : values.avgTimeDoD,
        };
      } catch (e) {
        return values;
      }
    })();

    const newIndicators = [
      {
        id: uniqueId(),
        plotName: 'footfall',
        title: 'Total Footfall',
        tooltip: 'Aggregated number of people in store at user selected dates and hours.',
        value: store.indoor_footfall_metrics || '0',
        icon: storeDoD.footfallDoD.icon,
        percent: storeDoD.footfallDoD.percentage,
        name: 'footfall',
        data: [
          {
            y: totalFootfallGraph.length === 1 ? [...totalFootfallGraph, 0] : totalFootfallGraph,
            hoverinfo: 'none',
            // hovertemplate: 'Footfall: %{y}<extra></extra>',
            fill: 'tozeroy',
            fillcolor: 'rgba(83, 194, 197, 0.1)',
            line: { color: '#53D2C5' },
            mode: 'lines',
          },
        ],
      },
      {
        id: uniqueId(),
        plotName: 'avg',
        title: 'Avg. Time',
        tooltip: 'Average Time people have spent in store at user selected dates and hours.',
        icon: storeDoD.avgTimeDoD.icon,
        percent: storeDoD.avgTimeDoD.percentage,
        value: averageTime,
        name: 'avgtime',
        data: [
          {
            y: avgTimeGraph.length === 1 ? [...avgTimeGraph, 0] : avgTimeGraph,
            hoverinfo: 'none',
            formatted: avgTimeGraphFormatted,
            // hoverinfo: 'text',
            hovertext: tooltipAvgTime,
            fill: 'tozeroy',
            fillcolor: 'rgba(83, 97, 255, 0.1)',
            line: { color: '#5361ff' },
            mode: 'lines',
          },
        ],
      },
    ];

    setIndicators(newIndicators);
  }, [store, storeToday, storeWeekGraphs, allStoresAvgTimeDoDGraphs, storeId, footfallDayOverDayGraphs]);

  const Filters = (
    <Row>
      <Col className="flex flex-row gap-5">
        <RangeDatePicker needTime />

        <PersonFilters
          title="Filters"
          filters={FILTERS}
          onApplyFilters={({ age, gender }) => {
            const ageFilter = age ? age.join(',') : undefined;
            const genderFilter = gender ? gender.join(',') : undefined;
            dispatch(ActionTypes.updateAge(ageFilter));
            dispatch(ActionTypes.updateGender(genderFilter));
          }}
          onClear={() => {
            dispatch(ActionTypes.updateAge(''));
            dispatch(ActionTypes.updateGender(''));
          }}
        />
      </Col>
    </Row>
  );

  return (
    <Layout
      title={headerData.location}
      subtitle={headerData.address}
      extraHeader={<ExtraHeaders footFall={headerData.footFall} timeSpent={headerData.timeSpent} />}
      loadingHeader={store_loading}
    >
      <div className="flex flex-col gap-2">
        {Filters}
        <SummaryReport intervalLabel={intervalLabel} />
        <Indicators indicators={indicators} />
        <PerformanceGraph
          intervalLabel={intervalLabel}
          performanceDataCSV={storePerformanceCSVDownloadData}
          performancePlotData={storePerformancePlotData}
          performancePlotLayout={storePerformancePlotLayout}
          type="store"
          loading={outsideTrafficLoading || storeGraphs_loading || storeWeekGraphsLoading}
        />
        <TimeAndPopularity
          endDate={endDate}
          startDate={startDate}
          intervalLabel={intervalLabel}
          indicators={indicators}
        />
        <QueueAndTraffic intervalLabel={intervalLabel} />
      </div>
    </Layout>
  );
};

export default StoreDetail;
