import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { ToggleSwitch } from 'react-dragswitch';

import 'react-dragswitch/dist/index.css';

const GREEN_COLOR = '#27AE60';
const NEUTRAL_200_COLOR = '#BFBFC4';

export type ToggleProps = {
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  handleColor?: string;
  focusShadow?: string;
  label?: string;
  justifyEnd?: boolean;
  plotName?: string;
};

export const Toggle = React.forwardRef<any, ToggleProps>(
  ({ className, label, justifyEnd, plotName, ...props }, ref) => {
    const [color, setColor] = useState(GREEN_COLOR);

    useEffect(() => {
      console.log({ plotName });
      if (plotName === undefined) {
        setColor(GREEN_COLOR);
      } else if (plotName === 'footfall') {
        setColor('#53D2C5');
      } else {
        setColor('#5361ff');
      }
    }, [plotName]);

    return (
      <div className={cx(
        'flex items-center gap-2',
        { 'justify-end': justifyEnd },
      )}
      >
        <span className="text-sm text-neutral-800">{label}</span>
        <ToggleSwitch
          ref={ref}
          className={cx(className)}
          onColor={color}
          offColor={NEUTRAL_200_COLOR}
          {...props}
        />
      </div>
    );
  },
);
